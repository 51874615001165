/* tslint:disable */
/* eslint-disable */
/**
 * Bitmaker API v1.0 Documentation
 * Bitmaker API Swagger Specification
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SpiderJobArg,
    SpiderJobArgFromJSON,
    SpiderJobArgFromJSONTyped,
    SpiderJobArgToJSON,
    SpiderJobEnvVar,
    SpiderJobEnvVarFromJSON,
    SpiderJobEnvVarFromJSONTyped,
    SpiderJobEnvVarToJSON,
    SpiderJobTag,
    SpiderJobTagFromJSON,
    SpiderJobTagFromJSONTyped,
    SpiderJobTagToJSON,
} from './';

/**
 * 
 * @export
 * @interface SpiderJobCreate
 */
export interface SpiderJobCreate {
    /**
     * 
     * @type {number}
     * @memberof SpiderJobCreate
     */
    readonly jid?: number;
    /**
     * 
     * @type {string}
     * @memberof SpiderJobCreate
     */
    readonly name?: string;
    /**
     * 
     * @type {Array<SpiderJobArg>}
     * @memberof SpiderJobCreate
     */
    args?: Array<SpiderJobArg>;
    /**
     * 
     * @type {Array<SpiderJobEnvVar>}
     * @memberof SpiderJobCreate
     */
    envVars?: Array<SpiderJobEnvVar>;
    /**
     * 
     * @type {Array<SpiderJobTag>}
     * @memberof SpiderJobCreate
     */
    tags?: Array<SpiderJobTag>;
    /**
     * 
     * @type {string}
     * @memberof SpiderJobCreate
     */
    readonly jobStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof SpiderJobCreate
     */
    cronjob?: number | null;
}

export function SpiderJobCreateFromJSON(json: any): SpiderJobCreate {
    return SpiderJobCreateFromJSONTyped(json, false);
}

export function SpiderJobCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpiderJobCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jid': !exists(json, 'jid') ? undefined : json['jid'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'args': !exists(json, 'args') ? undefined : ((json['args'] as Array<any>).map(SpiderJobArgFromJSON)),
        'envVars': !exists(json, 'env_vars') ? undefined : ((json['env_vars'] as Array<any>).map(SpiderJobEnvVarFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(SpiderJobTagFromJSON)),
        'jobStatus': !exists(json, 'job_status') ? undefined : json['job_status'],
        'cronjob': !exists(json, 'cronjob') ? undefined : json['cronjob'],
    };
}

export function SpiderJobCreateToJSON(value?: SpiderJobCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'args': value.args === undefined ? undefined : ((value.args as Array<any>).map(SpiderJobArgToJSON)),
        'env_vars': value.envVars === undefined ? undefined : ((value.envVars as Array<any>).map(SpiderJobEnvVarToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(SpiderJobTagToJSON)),
        'cronjob': value.cronjob,
    };
}


