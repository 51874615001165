/* tslint:disable */
/* eslint-disable */
/**
 * Bitmaker API v1.0 Documentation
 * Bitmaker API Swagger Specification
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Spider,
    SpiderFromJSON,
    SpiderFromJSONTyped,
    SpiderToJSON,
    UserDetail,
    UserDetailFromJSON,
    UserDetailFromJSONTyped,
    UserDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface Deploy
 */
export interface Deploy {
    /**
     * 
     * @type {number}
     * @memberof Deploy
     */
    readonly did?: number;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    project: string;
    /**
     * 
     * @type {UserDetail}
     * @memberof Deploy
     */
    user: UserDetail;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    status?: DeployStatusEnum;
    /**
     * 
     * @type {Array<Spider>}
     * @memberof Deploy
     */
    spiders?: Array<Spider>;
    /**
     * 
     * @type {Date}
     * @memberof Deploy
     */
    readonly created?: Date;
}

/**
* @export
* @enum {string}
*/
export enum DeployStatusEnum {
    Success = 'SUCCESS',
    Building = 'BUILDING',
    Failure = 'FAILURE',
    Canceled = 'CANCELED'
}

export function DeployFromJSON(json: any): Deploy {
    return DeployFromJSONTyped(json, false);
}

export function DeployFromJSONTyped(json: any, ignoreDiscriminator: boolean): Deploy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'did': !exists(json, 'did') ? undefined : json['did'],
        'project': json['project'],
        'user': UserDetailFromJSON(json['user']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'spiders': !exists(json, 'spiders') ? undefined : ((json['spiders'] as Array<any>).map(SpiderFromJSON)),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
    };
}

export function DeployToJSON(value?: Deploy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project': value.project,
        'user': UserDetailToJSON(value.user),
        'status': value.status,
        'spiders': value.spiders === undefined ? undefined : ((value.spiders as Array<any>).map(SpiderToJSON)),
    };
}


