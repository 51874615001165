/* tslint:disable */
/* eslint-disable */
/**
 * Bitmaker API v1.0 Documentation
 * Bitmaker API Swagger Specification
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeployUpdate
 */
export interface DeployUpdate {
    /**
     * 
     * @type {number}
     * @memberof DeployUpdate
     */
    readonly did?: number;
    /**
     * 
     * @type {string}
     * @memberof DeployUpdate
     */
    status?: DeployUpdateStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeployUpdate
     */
    spidersNames?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum DeployUpdateStatusEnum {
    Success = 'SUCCESS',
    Building = 'BUILDING',
    Failure = 'FAILURE',
    Canceled = 'CANCELED'
}

export function DeployUpdateFromJSON(json: any): DeployUpdate {
    return DeployUpdateFromJSONTyped(json, false);
}

export function DeployUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeployUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'did': !exists(json, 'did') ? undefined : json['did'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'spidersNames': !exists(json, 'spiders_names') ? undefined : json['spiders_names'],
    };
}

export function DeployUpdateToJSON(value?: DeployUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'spiders_names': value.spidersNames,
    };
}


