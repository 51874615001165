/* tslint:disable */
/* eslint-disable */
/**
 * Bitmaker API v1.0 Documentation
 * Bitmaker API Swagger Specification
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserDetail,
    UserDetailFromJSON,
    UserDetailFromJSONTyped,
    UserDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectUpdate
 */
export interface ProjectUpdate {
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdate
     */
    readonly pid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdate
     */
    name: string;
    /**
     * 
     * @type {Array<UserDetail>}
     * @memberof ProjectUpdate
     */
    users?: Array<UserDetail>;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdate
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdate
     */
    action?: ProjectUpdateActionEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdate
     */
    permission?: ProjectUpdatePermissionEnum;
}

/**
* @export
* @enum {string}
*/
export enum ProjectUpdateActionEnum {
    Remove = 'remove',
    Add = 'add'
}/**
* @export
* @enum {string}
*/
export enum ProjectUpdatePermissionEnum {
    Editor = 'EDITOR',
    Viewer = 'VIEWER'
}

export function ProjectUpdateFromJSON(json: any): ProjectUpdate {
    return ProjectUpdateFromJSONTyped(json, false);
}

export function ProjectUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pid': !exists(json, 'pid') ? undefined : json['pid'],
        'name': json['name'],
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(UserDetailFromJSON)),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'permission': !exists(json, 'permission') ? undefined : json['permission'],
    };
}

export function ProjectUpdateToJSON(value?: ProjectUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(UserDetailToJSON)),
        'email': value.email,
        'action': value.action,
        'permission': value.permission,
    };
}


