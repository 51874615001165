/* tslint:disable */
/* eslint-disable */
/**
 * Bitmaker API v1.0 Documentation
 * Bitmaker API Swagger Specification
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthToken,
    AuthTokenFromJSON,
    AuthTokenToJSON,
    Deploy,
    DeployFromJSON,
    DeployToJSON,
    DeployCreate,
    DeployCreateFromJSON,
    DeployCreateToJSON,
    DeployUpdate,
    DeployUpdateFromJSON,
    DeployUpdateToJSON,
    GetLogs,
    GetLogsFromJSON,
    GetLogsToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
    InlineResponse2003,
    InlineResponse2003FromJSON,
    InlineResponse2003ToJSON,
    InlineResponse2004,
    InlineResponse2004FromJSON,
    InlineResponse2004ToJSON,
    InlineResponse2005,
    InlineResponse2005FromJSON,
    InlineResponse2005ToJSON,
    Project,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectJob,
    ProjectJobFromJSON,
    ProjectJobToJSON,
    ProjectUpdate,
    ProjectUpdateFromJSON,
    ProjectUpdateToJSON,
    Spider,
    SpiderFromJSON,
    SpiderToJSON,
    SpiderCronJob,
    SpiderCronJobFromJSON,
    SpiderCronJobToJSON,
    SpiderCronJobCreate,
    SpiderCronJobCreateFromJSON,
    SpiderCronJobCreateToJSON,
    SpiderCronJobUpdate,
    SpiderCronJobUpdateFromJSON,
    SpiderCronJobUpdateToJSON,
    SpiderJob,
    SpiderJobFromJSON,
    SpiderJobToJSON,
    SpiderJobCreate,
    SpiderJobCreateFromJSON,
    SpiderJobCreateToJSON,
    SpiderJobUpdate,
    SpiderJobUpdateFromJSON,
    SpiderJobUpdateToJSON,
    Token,
    TokenFromJSON,
    TokenToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface ApiAuthLoginRequest {
    data: AuthToken;
}

export interface ApiAuthRegisterRequest {
    data: User;
}

export interface ApiProjectsCreateRequest {
    data: Project;
}

export interface ApiProjectsDeleteRequest {
    pid: string;
}

export interface ApiProjectsDeploysCreateRequest {
    pid: string;
    data: DeployCreate;
}

export interface ApiProjectsDeploysDeleteRequest {
    did: number;
    pid: string;
}

export interface ApiProjectsDeploysListRequest {
    pid: string;
    page?: number;
    pageSize?: number;
}

export interface ApiProjectsDeploysPartialUpdateRequest {
    did: number;
    pid: string;
    data: Deploy;
}

export interface ApiProjectsDeploysReadRequest {
    did: number;
    pid: string;
}

export interface ApiProjectsDeploysUpdateRequest {
    did: number;
    pid: string;
    data: DeployUpdate;
}

export interface ApiProjectsJobsRequest {
    pid: string;
    page?: number;
    pageSize?: number;
}

export interface ApiProjectsListRequest {
    page?: number;
    pageSize?: number;
}

export interface ApiProjectsPartialUpdateRequest {
    pid: string;
    data: Project;
}

export interface ApiProjectsReadRequest {
    pid: string;
}

export interface ApiProjectsSpidersCronjobsCreateRequest {
    pid: string;
    sid: string;
    data: SpiderCronJobCreate;
}

export interface ApiProjectsSpidersCronjobsListRequest {
    pid: string;
    sid: string;
    tag?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiProjectsSpidersCronjobsPartialUpdateRequest {
    cjid: string;
    pid: string;
    sid: string;
    data: SpiderCronJob;
}

export interface ApiProjectsSpidersCronjobsReadRequest {
    cjid: string;
    pid: string;
    sid: string;
}

export interface ApiProjectsSpidersCronjobsRunOnceRequest {
    cjid: string;
    pid: string;
    sid: string;
}

export interface ApiProjectsSpidersCronjobsUpdateRequest {
    cjid: string;
    pid: string;
    sid: string;
    data: SpiderCronJobUpdate;
}

export interface ApiProjectsSpidersJobsCreateRequest {
    pid: string;
    sid: string;
    data: SpiderJobCreate;
    async?: boolean;
}

export interface ApiProjectsSpidersJobsDataListRequest {
    jid: string;
    pid: string;
    sid: string;
    page?: number;
    pageSize?: number;
    type?: string;
}

export interface ApiProjectsSpidersJobsListRequest {
    pid: string;
    sid: string;
    cronjob?: number;
    status?: string;
    tag?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiProjectsSpidersJobsLogsRequest {
    jid: number;
    pid: string;
    sid: string;
    page?: number;
    pageSize?: number;
}

export interface ApiProjectsSpidersJobsPartialUpdateRequest {
    jid: number;
    pid: string;
    sid: string;
    data: SpiderJob;
}

export interface ApiProjectsSpidersJobsReadRequest {
    jid: number;
    pid: string;
    sid: string;
}

export interface ApiProjectsSpidersJobsUpdateRequest {
    jid: number;
    pid: string;
    sid: string;
    data: SpiderJobUpdate;
}

export interface ApiProjectsSpidersListRequest {
    pid: string;
    page?: number;
    pageSize?: number;
}

export interface ApiProjectsSpidersReadRequest {
    pid: string;
    sid: string;
}

export interface ApiProjectsUpdateRequest {
    pid: string;
    data: ProjectUpdate;
}

/**
 * 
 */
export class ApiApi extends runtime.BaseAPI {

    /**
     */
    async apiAuthActivateRaw(): Promise<runtime.ApiResponse<Array<AuthToken>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/auth/activate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuthTokenFromJSON));
    }

    /**
     */
    async apiAuthActivate(): Promise<Array<AuthToken>> {
        const response = await this.apiAuthActivateRaw();
        return await response.value();
    }

    /**
     */
    async apiAuthLoginRaw(requestParameters: ApiAuthLoginRequest): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiAuthLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthTokenToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthLogin(requestParameters: ApiAuthLoginRequest): Promise<Token> {
        const response = await this.apiAuthLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAuthRegisterRaw(requestParameters: ApiAuthRegisterRequest): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiAuthRegister.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/auth/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthRegister(requestParameters: ApiAuthRegisterRequest): Promise<Token> {
        const response = await this.apiAuthRegisterRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsCreateRaw(requestParameters: ApiProjectsCreateRequest): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsCreate(requestParameters: ApiProjectsCreateRequest): Promise<Project> {
        const response = await this.apiProjectsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsDeleteRaw(requestParameters: ApiProjectsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProjectsDelete(requestParameters: ApiProjectsDeleteRequest): Promise<void> {
        await this.apiProjectsDeleteRaw(requestParameters);
    }

    /**
     * Create a new Deploy
     */
    async apiProjectsDeploysCreateRaw(requestParameters: ApiProjectsDeploysCreateRequest): Promise<runtime.ApiResponse<DeployCreate>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsDeploysCreate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsDeploysCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/deploys`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeployCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeployCreateFromJSON(jsonValue));
    }

    /**
     * Create a new Deploy
     */
    async apiProjectsDeploysCreate(requestParameters: ApiProjectsDeploysCreateRequest): Promise<DeployCreate> {
        const response = await this.apiProjectsDeploysCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsDeploysDeleteRaw(requestParameters: ApiProjectsDeploysDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.did === null || requestParameters.did === undefined) {
            throw new runtime.RequiredError('did','Required parameter requestParameters.did was null or undefined when calling apiProjectsDeploysDelete.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsDeploysDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/deploys/{did}`.replace(`{${"did"}}`, encodeURIComponent(String(requestParameters.did))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProjectsDeploysDelete(requestParameters: ApiProjectsDeploysDeleteRequest): Promise<void> {
        await this.apiProjectsDeploysDeleteRaw(requestParameters);
    }

    /**
     */
    async apiProjectsDeploysListRaw(requestParameters: ApiProjectsDeploysListRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsDeploysList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/deploys`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsDeploysList(requestParameters: ApiProjectsDeploysListRequest): Promise<InlineResponse2001> {
        const response = await this.apiProjectsDeploysListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsDeploysPartialUpdateRaw(requestParameters: ApiProjectsDeploysPartialUpdateRequest): Promise<runtime.ApiResponse<Deploy>> {
        if (requestParameters.did === null || requestParameters.did === undefined) {
            throw new runtime.RequiredError('did','Required parameter requestParameters.did was null or undefined when calling apiProjectsDeploysPartialUpdate.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsDeploysPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsDeploysPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/deploys/{did}`.replace(`{${"did"}}`, encodeURIComponent(String(requestParameters.did))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DeployToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeployFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsDeploysPartialUpdate(requestParameters: ApiProjectsDeploysPartialUpdateRequest): Promise<Deploy> {
        const response = await this.apiProjectsDeploysPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsDeploysReadRaw(requestParameters: ApiProjectsDeploysReadRequest): Promise<runtime.ApiResponse<Deploy>> {
        if (requestParameters.did === null || requestParameters.did === undefined) {
            throw new runtime.RequiredError('did','Required parameter requestParameters.did was null or undefined when calling apiProjectsDeploysRead.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsDeploysRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/deploys/{did}`.replace(`{${"did"}}`, encodeURIComponent(String(requestParameters.did))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeployFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsDeploysRead(requestParameters: ApiProjectsDeploysReadRequest): Promise<Deploy> {
        const response = await this.apiProjectsDeploysReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsDeploysUpdateRaw(requestParameters: ApiProjectsDeploysUpdateRequest): Promise<runtime.ApiResponse<DeployUpdate>> {
        if (requestParameters.did === null || requestParameters.did === undefined) {
            throw new runtime.RequiredError('did','Required parameter requestParameters.did was null or undefined when calling apiProjectsDeploysUpdate.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsDeploysUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsDeploysUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/deploys/{did}`.replace(`{${"did"}}`, encodeURIComponent(String(requestParameters.did))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeployUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeployUpdateFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsDeploysUpdate(requestParameters: ApiProjectsDeploysUpdateRequest): Promise<DeployUpdate> {
        const response = await this.apiProjectsDeploysUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsJobsRaw(requestParameters: ApiProjectsJobsRequest): Promise<runtime.ApiResponse<ProjectJob>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/jobs`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectJobFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsJobs(requestParameters: ApiProjectsJobsRequest): Promise<ProjectJob> {
        const response = await this.apiProjectsJobsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsListRaw(requestParameters: ApiProjectsListRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsList(requestParameters: ApiProjectsListRequest): Promise<InlineResponse200> {
        const response = await this.apiProjectsListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsPartialUpdateRaw(requestParameters: ApiProjectsPartialUpdateRequest): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsPartialUpdate(requestParameters: ApiProjectsPartialUpdateRequest): Promise<Project> {
        const response = await this.apiProjectsPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsReadRaw(requestParameters: ApiProjectsReadRequest): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsRead(requestParameters: ApiProjectsReadRequest): Promise<Project> {
        const response = await this.apiProjectsReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersCronjobsCreateRaw(requestParameters: ApiProjectsSpidersCronjobsCreateRequest): Promise<runtime.ApiResponse<SpiderCronJobCreate>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersCronjobsCreate.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersCronjobsCreate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsSpidersCronjobsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/cronjobs`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SpiderCronJobCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpiderCronJobCreateFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersCronjobsCreate(requestParameters: ApiProjectsSpidersCronjobsCreateRequest): Promise<SpiderCronJobCreate> {
        const response = await this.apiProjectsSpidersCronjobsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersCronjobsListRaw(requestParameters: ApiProjectsSpidersCronjobsListRequest): Promise<runtime.ApiResponse<InlineResponse2003>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersCronjobsList.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersCronjobsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tag !== undefined) {
            queryParameters['tag'] = requestParameters.tag;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/cronjobs`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2003FromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersCronjobsList(requestParameters: ApiProjectsSpidersCronjobsListRequest): Promise<InlineResponse2003> {
        const response = await this.apiProjectsSpidersCronjobsListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersCronjobsPartialUpdateRaw(requestParameters: ApiProjectsSpidersCronjobsPartialUpdateRequest): Promise<runtime.ApiResponse<SpiderCronJob>> {
        if (requestParameters.cjid === null || requestParameters.cjid === undefined) {
            throw new runtime.RequiredError('cjid','Required parameter requestParameters.cjid was null or undefined when calling apiProjectsSpidersCronjobsPartialUpdate.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersCronjobsPartialUpdate.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersCronjobsPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsSpidersCronjobsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/cronjobs/{cjid}`.replace(`{${"cjid"}}`, encodeURIComponent(String(requestParameters.cjid))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SpiderCronJobToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpiderCronJobFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersCronjobsPartialUpdate(requestParameters: ApiProjectsSpidersCronjobsPartialUpdateRequest): Promise<SpiderCronJob> {
        const response = await this.apiProjectsSpidersCronjobsPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersCronjobsReadRaw(requestParameters: ApiProjectsSpidersCronjobsReadRequest): Promise<runtime.ApiResponse<SpiderCronJob>> {
        if (requestParameters.cjid === null || requestParameters.cjid === undefined) {
            throw new runtime.RequiredError('cjid','Required parameter requestParameters.cjid was null or undefined when calling apiProjectsSpidersCronjobsRead.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersCronjobsRead.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersCronjobsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/cronjobs/{cjid}`.replace(`{${"cjid"}}`, encodeURIComponent(String(requestParameters.cjid))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpiderCronJobFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersCronjobsRead(requestParameters: ApiProjectsSpidersCronjobsReadRequest): Promise<SpiderCronJob> {
        const response = await this.apiProjectsSpidersCronjobsReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersCronjobsRunOnceRaw(requestParameters: ApiProjectsSpidersCronjobsRunOnceRequest): Promise<runtime.ApiResponse<SpiderCronJob>> {
        if (requestParameters.cjid === null || requestParameters.cjid === undefined) {
            throw new runtime.RequiredError('cjid','Required parameter requestParameters.cjid was null or undefined when calling apiProjectsSpidersCronjobsRunOnce.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersCronjobsRunOnce.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersCronjobsRunOnce.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/cronjobs/{cjid}/run_once`.replace(`{${"cjid"}}`, encodeURIComponent(String(requestParameters.cjid))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpiderCronJobFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersCronjobsRunOnce(requestParameters: ApiProjectsSpidersCronjobsRunOnceRequest): Promise<SpiderCronJob> {
        const response = await this.apiProjectsSpidersCronjobsRunOnceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersCronjobsUpdateRaw(requestParameters: ApiProjectsSpidersCronjobsUpdateRequest): Promise<runtime.ApiResponse<SpiderCronJobUpdate>> {
        if (requestParameters.cjid === null || requestParameters.cjid === undefined) {
            throw new runtime.RequiredError('cjid','Required parameter requestParameters.cjid was null or undefined when calling apiProjectsSpidersCronjobsUpdate.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersCronjobsUpdate.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersCronjobsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsSpidersCronjobsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/cronjobs/{cjid}`.replace(`{${"cjid"}}`, encodeURIComponent(String(requestParameters.cjid))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SpiderCronJobUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpiderCronJobUpdateFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersCronjobsUpdate(requestParameters: ApiProjectsSpidersCronjobsUpdateRequest): Promise<SpiderCronJobUpdate> {
        const response = await this.apiProjectsSpidersCronjobsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersJobsCreateRaw(requestParameters: ApiProjectsSpidersJobsCreateRequest): Promise<runtime.ApiResponse<SpiderJobCreate>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersJobsCreate.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersJobsCreate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsSpidersJobsCreate.');
        }

        const queryParameters: any = {};

        if (requestParameters.async !== undefined) {
            queryParameters['async'] = requestParameters.async;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/jobs`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SpiderJobCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpiderJobCreateFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersJobsCreate(requestParameters: ApiProjectsSpidersJobsCreateRequest): Promise<SpiderJobCreate> {
        const response = await this.apiProjectsSpidersJobsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersJobsDataListRaw(requestParameters: ApiProjectsSpidersJobsDataListRequest): Promise<runtime.ApiResponse<InlineResponse2005>> {
        if (requestParameters.jid === null || requestParameters.jid === undefined) {
            throw new runtime.RequiredError('jid','Required parameter requestParameters.jid was null or undefined when calling apiProjectsSpidersJobsDataList.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersJobsDataList.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersJobsDataList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/jobs/{jid}/data`.replace(`{${"jid"}}`, encodeURIComponent(String(requestParameters.jid))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2005FromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersJobsDataList(requestParameters: ApiProjectsSpidersJobsDataListRequest): Promise<InlineResponse2005> {
        const response = await this.apiProjectsSpidersJobsDataListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersJobsListRaw(requestParameters: ApiProjectsSpidersJobsListRequest): Promise<runtime.ApiResponse<InlineResponse2004>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersJobsList.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersJobsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.cronjob !== undefined) {
            queryParameters['cronjob'] = requestParameters.cronjob;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.tag !== undefined) {
            queryParameters['tag'] = requestParameters.tag;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/jobs`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2004FromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersJobsList(requestParameters: ApiProjectsSpidersJobsListRequest): Promise<InlineResponse2004> {
        const response = await this.apiProjectsSpidersJobsListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersJobsLogsRaw(requestParameters: ApiProjectsSpidersJobsLogsRequest): Promise<runtime.ApiResponse<GetLogs>> {
        if (requestParameters.jid === null || requestParameters.jid === undefined) {
            throw new runtime.RequiredError('jid','Required parameter requestParameters.jid was null or undefined when calling apiProjectsSpidersJobsLogs.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersJobsLogs.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersJobsLogs.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/jobs/{jid}/logs`.replace(`{${"jid"}}`, encodeURIComponent(String(requestParameters.jid))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLogsFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersJobsLogs(requestParameters: ApiProjectsSpidersJobsLogsRequest): Promise<GetLogs> {
        const response = await this.apiProjectsSpidersJobsLogsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersJobsPartialUpdateRaw(requestParameters: ApiProjectsSpidersJobsPartialUpdateRequest): Promise<runtime.ApiResponse<SpiderJob>> {
        if (requestParameters.jid === null || requestParameters.jid === undefined) {
            throw new runtime.RequiredError('jid','Required parameter requestParameters.jid was null or undefined when calling apiProjectsSpidersJobsPartialUpdate.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersJobsPartialUpdate.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersJobsPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsSpidersJobsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/jobs/{jid}`.replace(`{${"jid"}}`, encodeURIComponent(String(requestParameters.jid))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SpiderJobToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpiderJobFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersJobsPartialUpdate(requestParameters: ApiProjectsSpidersJobsPartialUpdateRequest): Promise<SpiderJob> {
        const response = await this.apiProjectsSpidersJobsPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersJobsReadRaw(requestParameters: ApiProjectsSpidersJobsReadRequest): Promise<runtime.ApiResponse<SpiderJob>> {
        if (requestParameters.jid === null || requestParameters.jid === undefined) {
            throw new runtime.RequiredError('jid','Required parameter requestParameters.jid was null or undefined when calling apiProjectsSpidersJobsRead.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersJobsRead.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersJobsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/jobs/{jid}`.replace(`{${"jid"}}`, encodeURIComponent(String(requestParameters.jid))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpiderJobFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersJobsRead(requestParameters: ApiProjectsSpidersJobsReadRequest): Promise<SpiderJob> {
        const response = await this.apiProjectsSpidersJobsReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersJobsUpdateRaw(requestParameters: ApiProjectsSpidersJobsUpdateRequest): Promise<runtime.ApiResponse<SpiderJobUpdate>> {
        if (requestParameters.jid === null || requestParameters.jid === undefined) {
            throw new runtime.RequiredError('jid','Required parameter requestParameters.jid was null or undefined when calling apiProjectsSpidersJobsUpdate.');
        }

        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersJobsUpdate.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersJobsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsSpidersJobsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}/jobs/{jid}`.replace(`{${"jid"}}`, encodeURIComponent(String(requestParameters.jid))).replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SpiderJobUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpiderJobUpdateFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersJobsUpdate(requestParameters: ApiProjectsSpidersJobsUpdateRequest): Promise<SpiderJobUpdate> {
        const response = await this.apiProjectsSpidersJobsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersListRaw(requestParameters: ApiProjectsSpidersListRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersList(requestParameters: ApiProjectsSpidersListRequest): Promise<InlineResponse2002> {
        const response = await this.apiProjectsSpidersListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiProjectsSpidersReadRaw(requestParameters: ApiProjectsSpidersReadRequest): Promise<runtime.ApiResponse<Spider>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsSpidersRead.');
        }

        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid','Required parameter requestParameters.sid was null or undefined when calling apiProjectsSpidersRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}/spiders/{sid}`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))).replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpiderFromJSON(jsonValue));
    }

    /**
     */
    async apiProjectsSpidersRead(requestParameters: ApiProjectsSpidersReadRequest): Promise<Spider> {
        const response = await this.apiProjectsSpidersReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update Project information
     */
    async apiProjectsUpdateRaw(requestParameters: ApiProjectsUpdateRequest): Promise<runtime.ApiResponse<ProjectUpdate>> {
        if (requestParameters.pid === null || requestParameters.pid === undefined) {
            throw new runtime.RequiredError('pid','Required parameter requestParameters.pid was null or undefined when calling apiProjectsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiProjectsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{pid}`.replace(`{${"pid"}}`, encodeURIComponent(String(requestParameters.pid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectUpdateFromJSON(jsonValue));
    }

    /**
     * Update Project information
     */
    async apiProjectsUpdate(requestParameters: ApiProjectsUpdateRequest): Promise<ProjectUpdate> {
        const response = await this.apiProjectsUpdateRaw(requestParameters);
        return await response.value();
    }

}
